import {formatDigits} from "./utils";

export function formatUsage(usageValue: number | null, range: [number, number]): string {
  if (usageValue === null) {
    return 'No Data';
  }

  // Don't return decimal places if over 100
  const min = range[0];
  if (min >= 100) {
    return formatDigits(0).format(usageValue);
  }

  // Return 2 decimal places if the largest value is 1
  // or if the value is small enough that it will otherwise round down to 0.0
  const max = range[1];
  if (max <= 1 || (usageValue > 0 && usageValue < 0.05)) {
    return formatDigits(2).format(usageValue);
  }

  return formatDigits(1).format(usageValue);
}
