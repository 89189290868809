import React, { FunctionComponent, useEffect, useState } from 'react';
import { formatDigits, jsonEqual, sumOrNull } from '../../util/utils';
import './TimeOfUseGraph.scss';
import './Tooltip.scss';
import {
  Bar,
  Scatter,
  LabelList,
  Line,
  Area,
  YAxis,
  ResponsiveContainer,
  ComposedChart,
  Tooltip,
  TooltipProps,
} from 'recharts';
import { GraphRecord, GraphValues } from './GraphRecord';
import { TimeOfUseBar } from './TimeOfUseBar';
import { TemperatureGraphHelper } from '../TemperatureData/TemperatureDataHelper';
import { UsageGraphHelper } from '../TimeOfUseData/TimeOfUseDataHelper';
import { UsageData } from '../TimeOfUseData/TimeOfUseData';
import { Spinner } from '../Spinner/Spinner';
import { useInterval } from '../../hooks/parameters/useInterval';
import { useSelectedDateRange } from '../../contexts/DateContext/useSelectedDateRange';
import { dateTicks, TimeAxis } from '../Axis/TimeAxis/TimeAxis';
import { ConsumptionAxis } from '../Axis/ConsumptionAxis/ConsumptionAxis';
import { useMode } from '../../hooks/parameters/useMode';
import { HighTempTriangle, LowTempTriangle } from './Triangles';
import { formatEST } from '../../common/dates/dates';
import { curveCatmullRom } from 'd3-shape';
import { useProductType } from '../../hooks/parameters/useProductType';
import {formatUsage} from "../../util/usage";

interface Props {
  mode: string;
  usage: UsageGraphHelper;
  temperatures: TemperatureGraphHelper;
  graphData: GraphRecord[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function RecordTempContent(props: any): JSX.Element {
  if (props.value !== null && props.value !== undefined) {
    const formatted = formatDigits(0).format(props.value) + '°';
    return (
      <text x={props.cx} y={props.cy} dy={props.dy} width={props.width} height={props.height} textAnchor="middle">
        {formatted}
      </text>
    );
  }

  return null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function recordLowestTemp(record: any): number {
  const value = record.temp?.low;
  if (record.lowestTemp && value !== undefined) {
    return value;
  }
  return null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function recordHighestTemp(record: any): number {
  const value = record.temp?.high;
  if (record.highestTemp && value !== undefined) {
    return value;
  }
  return null;
}

/**
 * Text labels for the high / low points on the chart
 */
function HighLowPoints(): JSX.Element[] {
  const points = new Array<JSX.Element>();

  points.push(
    <Scatter
      key="lowTemperature"
      className="lowTemperature"
      dataKey={recordLowestTemp}
      yAxisId="temperature"
      isAnimationActive={false}
      shape={<LowTempTriangle />}
    >
      <LabelList dataKey={recordLowestTemp} content={<RecordTempContent dy="1.35em" />} position="bottom"></LabelList>
    </Scatter>
  );

  points.push(
    <Scatter
      key="highTemperature"
      className="highTemperature"
      dataKey={recordHighestTemp}
      yAxisId="temperature"
      isAnimationActive={false}
      shape={<HighTempTriangle />}
    >
      <LabelList dataKey={recordHighestTemp} content={<RecordTempContent dy="-0.5em" />} position="top"></LabelList>
    </Scatter>
  );

  return points;
}

// How much space for the temperature line
const graphPercentTemperature = 20;
// How much space for the consumption bars
const graphPercentConsumption = 65;
// How much space above and below the temperature line
const graphPercentSpacer = 9;

function TemperatureAxis(domain: [number, number]): JSX.Element {
  const min = domain[0];
  const max = domain[1];

  const delta = max - min || 5;
  const newMin = min - (delta / graphPercentTemperature) * (graphPercentConsumption + graphPercentSpacer);
  const newMax = max + (delta / graphPercentTemperature) * graphPercentSpacer;

  return <YAxis domain={[newMin, newMax]} yAxisId="temperature" className="temperatureAxis" hide={true} />;
}

interface RelevantContext {
  interval: string;
  startDate: Date;
  endDate: Date;
}

export function useRelevantContext(): RelevantContext {
  const [interval] = useInterval();
  const [relevantContext, setRelevantContext] = useState<RelevantContext | null>(null);

  const [{ start: startDate, end: endDate }] = useSelectedDateRange();

  useEffect(() => {
    if (!interval || !startDate || !endDate) {
      setRelevantContext(null);
    } else {
      setRelevantContext({
        interval: interval,
        startDate: startDate,
        endDate: endDate,
      });
    }
  }, [interval, startDate, endDate]);

  return relevantContext;
}

function getCostValue(data: UsageData | null): number | null | undefined {
  return data?.costEstimate;
}

function getCostEstimate(data: UsageData | null): number | null | undefined {
  return data?.missingCostEstimate;
}

function getCostTotal(data: UsageData | null): number | null | undefined {
  return sumOrNull([data?.costEstimate, data?.missingCostEstimate]);
}

function getUsageValue(data: UsageData | null): number | null | undefined {
  return data?.usage;
}

function getUsageEstimate(data: UsageData | null): number | null | undefined {
  return data?.missingUsageEstimate;
}

function getUsageTotal(data: UsageData | null): number | null | undefined {
  return sumOrNull([data?.usage, data?.missingUsageEstimate]);
}

function valuesForMidpoint(mode: string, usage: UsageGraphHelper, midpoint: number): GraphValues {
  const standardUsage = usage.dataByMidpointAndTimeOfDay(midpoint, '');
  const peakUsage = usage.dataByMidpointAndTimeOfDay(midpoint, 'PEAK');
  const offpeakUsage = usage.dataByMidpointAndTimeOfDay(midpoint, 'OFFPEAK');
  const superOffpeakUsage = usage.dataByMidpointAndTimeOfDay(midpoint, 'SUPEROFFPEAK');

  // Cost
  if (mode === 'cost') {
    const total = sumOrNull([
      standardUsage?.totalCostEstimate,
      peakUsage?.totalCostEstimate,
      offpeakUsage?.totalCostEstimate,
      superOffpeakUsage?.totalCostEstimate
    ]);

    return {
      total: total,
      getValue: getCostValue,
      getEstimate: getCostEstimate,
      getTotal: getCostTotal,
      standard: standardUsage,
      peak: peakUsage,
      offpeak: offpeakUsage,
      superOffpeak: superOffpeakUsage
    };
  }

  const total = sumOrNull([standardUsage?.totalUsage, peakUsage?.totalUsage, offpeakUsage?.totalUsage, superOffpeakUsage?.totalUsage]);

  // Usage
  return {
    total: total,
    getValue: getUsageValue,
    getEstimate: getUsageEstimate,
    getTotal: getUsageTotal,
    standard: standardUsage,
    peak: peakUsage,
    offpeak: offpeakUsage,
    superOffpeak: superOffpeakUsage
  };
}

function getGraphData(
  mode: string,
  interval: string,
  context: RelevantContext,
  usage: UsageGraphHelper,
  temperatures: TemperatureGraphHelper
): GraphRecord[] | null {
  const startDate = context.startDate;
  const endDate = context.endDate;

  const ticks = dateTicks(interval, startDate, endDate, true);
  const tempData = temperatures.data || [];

  const graphRecords: GraphRecord[] = ticks.map((midpoint, index) => {
    const graphRecord: GraphRecord = {
      interval: interval,
      midpoint: midpoint,
      temp: temperatures.dataByMidpoint(midpoint),
      lowestTemp: temperatures.lowestData?.midpoint === midpoint,
      highestTemp: temperatures.highestData?.midpoint === midpoint,
      values: valuesForMidpoint(mode, usage, midpoint),
    };

    if (tempData) {
      if (index === 0) {
        graphRecord.temp = {
          ...tempData[0],
          midpoint: midpoint,
        };
        graphRecord.hideTooltip = true;
      }
      if (index === ticks.length - 1) {
        graphRecord.temp = {
          ...tempData[tempData.length - 1],
          midpoint: midpoint,
        };
        graphRecord.hideTooltip = true;
      }
    }

    return graphRecord;
  });

  return graphRecords;
}

export function useGraphRecords(
  mode: string,
  usage: UsageGraphHelper | undefined,
  temperatures: TemperatureGraphHelper | undefined
): GraphRecord[] | null {
  const context = useRelevantContext();
  const [interval] = useInterval();
  const [graphData, setGraphData] = useState<GraphRecord[] | null>(null);

  useEffect(() => {
    if (context && interval && usage && temperatures) {
      setGraphData(getGraphData(mode, interval, context, usage, temperatures));
    } else {
      setGraphData(null);
    }
  }, [mode, interval, context, usage, temperatures]);

  return graphData;
}

function recordTempRange(record: GraphRecord): [number, number] | undefined {
  return record.temp?.range;
}

function recordTempAverage(record: GraphRecord): number | undefined {
  return record.temp?.average;
}

function recordTotal(record: GraphRecord): number | null | undefined {
  return record.values.total;
}

interface GraphRecordProps {
  data: GraphRecord;
  min?: number;
}

const TooltipUsage: FunctionComponent<GraphRecordProps> = (props: GraphRecordProps): JSX.Element => {
  const [productType] = useProductType();

  const values = props?.data?.values;
  if (!values) {
    return null;
  }

  let unit = 'kWh';
  if (productType === 'gas') {
    unit = 'ccf';
  }

  let total = 0;
  total += values.standard?.usage || 0;
  total += values.peak?.usage || 0;
  total += values.offpeak?.usage || 0;
  total += values.superOffpeak?.usage || 0;

  const range: [number, number] = [props.min, total];
  const usage = formatUsage(total, range);

  return (
    <tr>
      <th>Energy Use</th>
      <td>
        {usage} {unit}
      </td>
    </tr>
  );
};

const TooltipTemperatures: FunctionComponent<GraphRecordProps> = (props: GraphRecordProps): JSX.Element => {
  const [interval] = useInterval();

  const tempData = props?.data?.temp;
  if (!tempData) {
    return null;
  }

  let tempString = `${tempData.average}° F`;
  if (interval !== 'hour') {
    tempString = `${tempData.low}° F - ${tempData.high}° F`;
  }

  return (
    <tr>
      <th>Temp</th>
      <td>{tempString}</td>
    </tr>
  );
};

const TooltipIntervalLabel: FunctionComponent<GraphRecordProps> = (props: GraphRecordProps): JSX.Element => {
  const [interval] = useInterval();

  const midpoint = props?.data?.midpoint;
  if (midpoint === undefined || midpoint === null) {
    return;
  }
  const date = new Date(midpoint);
  let dateString;

  if (interval === 'month') {
    dateString = formatEST(date, 'MMMM yyyy');
  } else if (interval === 'day') {
    dateString = formatEST(date, 'MMMM d');
  } else {
    let hourOfDay = midpoint;
    const suffix = hourOfDay >= 12 ? ' p.m.' : ' a.m.';
    if (hourOfDay > 12) {
      hourOfDay = hourOfDay - 12;
    } else if (hourOfDay === 0) {
      hourOfDay = 12;
    }
    dateString = `${hourOfDay} ${suffix}`;
  }

  if (!dateString) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="intervalLabel">{dateString}</div>
    </React.Fragment>
  );
};

interface CustomTooltipProps extends TooltipProps<string | number, string | number> {
  min: number;
}

const CustomTooltip = (props: CustomTooltipProps) => {
  const active = props.active;
  if (!active) {
    return;
  }

  const data = props.payload?.[0]?.payload as GraphRecord;
  if (!data || data.hideTooltip) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="custom-tooltip">
        <div className="inner-tooltip">
          <TooltipIntervalLabel data={data} />
          <table>
            <tbody>
              <TooltipUsage data={data} min={props.min} />
              <TooltipTemperatures data={data} />
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
};

interface GraphProps {
  mode: string;
  interval: string;
  startDate: Date;
  endDate: Date;
  min: number;
  max: number;
  tempHighest: number;
  tempLowest: number;
  graphData: GraphRecord[];
  children?: React.ReactNode;
}
function _Graph(props: GraphProps): JSX.Element | null {
  const ticks = dateTicks(props.interval, props.startDate, props.endDate, true);

  // Only show high / low area day & month views
  let area = null;
  if (props.interval !== 'hour') {
    area = (
      <Area
        type={curveCatmullRom}
        dataKey={recordTempRange}
        isAnimationActive={false}
        fill={'url(#redToBlue)'}
        fillOpacity={1}
        stroke={'#000000'}
        strokeWidth={0}
        yAxisId="temperature"
      />
    );
  }

  // TODO: scale this properly again
  return (
    <div className="comboGraph">
      <span className="sr-only">
        Line graph of temperature by {props.interval} showing a low of {props.tempLowest} degrees Fahrenheit and a high
        of {props.tempHighest} degrees. Full temperature data is available in a table below.
      </span>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          width={700}
          height={160}
          data={props.graphData}
          margin={{ bottom: 30, left: 15, right: 15 }}
          barGap={0}
          barCategoryGap={0}
        >
          {TimeAxis(ticks, props.graphData)}
          {TemperatureAxis([props.tempLowest, props.tempHighest])}
          {ConsumptionAxis(props.max)}
          {area}
          <Line
            className="averageTemperature"
            dataKey={recordTempAverage}
            stroke="#1E3575"
            strokeWidth="1.5"
            type={curveCatmullRom}
            dot={false}
            isAnimationActive={false}
            strokeDasharray="none"
            yAxisId="temperature"
          />
          <Bar dataKey={recordTotal} shape={<TimeOfUseBar />} isAnimationActive={false} yAxisId="consumption" />
          {HighLowPoints()}
          <Tooltip content={<CustomTooltip min={props.min} />} wrapperStyle={{ outline: 'none' }} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}
const Graph = React.memo(_Graph, jsonEqual);

export const TimeOfUseGraph: React.FunctionComponent<Props> = (props: Props) => {
  const [mode] = useMode();
  const usage = props.usage;
  const temperatures = props.temperatures;
  const context = useRelevantContext();
  const graphData = props.graphData;

  if (!context || !graphData) {
    return <Spinner className="comboGraph" />;
  }

  const min = usage.minInterval() || 0;
  const max = usage.maxInterval(mode) || 0;

  return (
    <Graph
      interval={context.interval}
      startDate={context.startDate}
      endDate={context.endDate}
      mode={mode}
      tempHighest={temperatures.highest}
      tempLowest={temperatures.lowest}
      min={min}
      max={max}
      graphData={graphData}
    />
  );
};
